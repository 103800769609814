<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-05-27 15:48:23
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2023-07-11 23:12:57
 * @FilePath: \personal\src\views\ocr\index.vue
 * @Description: ocr 识别
-->
<template>
  <div class="content-ocr">
    <div class="nav-title">
      <i></i>首页—  OCR识别 
    </div>
    <div class="content">
      <div class="title">
        <span class="active">图书管理 </span>
      </div>
      <div class="list">
        <div class="top">
          <div style="display: flex; align-items: center;">
            <div class="addBook" v-if="showType !== 'shopwindow'" @click="handleAdd">添加图书</div>
            <div class="left">
              <div class="sort" @click="handleCreation">
                <span>创建时间</span>
                <img src="../../assets/icon-nosort.png" v-if="this.sortType == 'handleTime'" alt="" />
                <div v-else>
                  <img src="../../assets/paixu1.png" v-if="createTime" alt="" />
                  <img src="../../assets/paixu2.png" v-if="!createTime" alt="" />
                </div>
              </div>
              <div class="sort" @click="handleOperation">
                <span>操作时间</span>
                <img src="../../assets/icon-nosort.png" v-if="this.sortType == 'createTime'" alt="" />
                <div v-else>
                  <img src="../../assets/paixu1.png" v-if="handleTime" alt="" />
                  <img src="../../assets/paixu2.png" v-if="!handleTime" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="input">
              <input type="text" v-model="inputValue" placeholder="输入图书名称" @keyup.enter="handleSearch">
              <img src="../../assets/fangdajing.png" alt="" @click="handleSearch">
            </div>
            <div class="showType">
              <span>显示模式</span>
              <img src="../../assets/chuchuang1.png" v-if="showType === 'shopwindow'" alt="" />
              <img src="../../assets/liebiao2.png"  v-if="showType === 'shopwindow'"  alt="" @click="handleListType" />
              <img src="../../assets/chuchuang2.png" v-if="showType !== 'shopwindow'" alt=""  @click="handleShopwindowType" />
              <img src="../../assets/liebiao1.png"  v-if="showType !== 'shopwindow'"  alt="" />
            </div>
            <span class="button-search" @click="handleDown">下载</span>
            <span class="button-cancle" @click="handleDelete">删除</span>
            <span class="button-primary" @click="handleUp">客户端上传</span>
          </div>
        </div>
        <div class="bottom">
          <div v-if="showType === 'shopwindow'"  class="bottom-content">
            <div class="add-wrap">
              <img src="../../assets/bookbeijing.png" alt="">
              <div class="add" @click="handleAdd">
                <img src="../../assets/add.png" alt="">
              </div>
            </div>
            <Shopwindow 
              :handleEdit="handleEdit"
              v-for="(item, index) in bookList"
              :key="index"
              :bookItem="item"
              :userInfo="userInfo"
              :handleCheckedBook="handleCheckedBook"
              :checkedList="checkedList"
              @refresh="handleOcrList"
              @setUserInfo="setUserInfo"
            />
          </div>
          <div class="book-list" v-else>
            <a-spin :spinning="loading" tip="正在生成文档...">
              <List 
                :list="bookList"
                :handleCheckedBook="handleCheckedBook"
                :checkedList="checkedList"
                @refresh="handleOcrList"
                @openUrlLoading="openUrlLoading"
              />
            </a-spin>
          </div>
        </div>
       
        <div class="pagination">
          <a-pagination
            v-model="pageNum"
            :total="total"
            show-size-changer
            :page-size="pageSize"
            @showSizeChange="onShowSizeChange"
            :show-total="total => `共 ${total} 条`"
            :pageSizeOptions="pageSizeOptions"
            @change="handleChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>

    <!-- 图书编辑新增 -->
    <AddOrEditModal
      v-if="visible"
      :visible="visible"
      :handleClose ="handleClose"
      :type="type"
      :handleOcrList="handleOcrList"
      :addOcrList="addOcrList"
      :id="id"
    />

    <!-- 删除提示 -->
    <HintOrDownModal
      v-if="deleteVisible"
      :visible="deleteVisible"
      :handleClose="handleDeleteClose"
      :tipsType="modalType"
      :handleCallback="handleDeleteCallBack"
      :picNum="picNum"
      :boxNum="this.checkedList.length"
      :textNum="textNum"
    />

    <!-- 下载提示 -->
    <DownModal
      v-if="downVisible"
      :visible="downVisible"
      :handleClose="handleDownClose"
      :handleCallback="handleDownCallBack"
      :bookNameList="bookNameList"
    />
    <ClientDownModal v-if="clientVisible" :visible="clientVisible" :handleClose="handleClientClose"/>
  </div>
</template>

<script>
import Shopwindow from '@/components/Shopwindow.vue';
import List from '@/components/List.vue';
import { Pagination } from 'ant-design-vue';
import AddOrEditModal from '@/components/AddOrEditModal.vue'
import HintOrDownModal from '@/components/HintOrDownModal.vue'
import DownModal from '@/components/DownModal.vue'
import { ocrBookList, deleteBook } from '@/api/ocr'
import { downloadByUrl } from '@/utils/download'
import Cookies from 'js-cookie';
import ClientDownModal from '@/components/ClientDownModal.vue'

export default {
  components: { 
    Shopwindow,
    List,
    Pagination,
    AddOrEditModal,
    HintOrDownModal,
    DownModal,
    ClientDownModal
  },
  created() {
    this.handleOcrList()
  },
  data() {
    return {
      /** input Search */
      inputValue: '',
      pageNum: 1,
      pageSize: 9,
      total: 0,
      /** 创建时间排序  */
      createTime : false,
      /** 操作时间排序*/
      handleTime: false,
      /** 显示模式 默认 橱窗模式  */
      showType: 'shopwindow',
      /** 弹窗visible */
      visible: false,
      /** 弹窗类型 新增 or 编辑 */
      type: 'add',
      /** 图书列表 */
      bookList: [],
      /** 图书id */
      id: '',
      /** 选中的图书 */
      checkedList: [], 
      /** HintOrDownModal visible */
      downVisible: false,
      title: '下载',
      sortType: 'createTime',
      deleteVisible: false,
      modalType: 'tipsTwo',
      picNum: 0,
      textNum: 0,
      pageSizeOptions: ['9', '19', '29', '39'],
      bookNameList: [], // 选中的下载图书name列表
      loading: false,
      clientVisible: false
    };
  },
   mounted() {
    this.userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {}
  },
  methods: {
    openUrlLoading(data) {
      this.loading = data
    },
    // 更新用户信息
    setUserInfo(data) {
      this.userInfo = data
    },
    /** 排序 */
    handleCreation() {
      this.sortType = "createTime"
      this.createTime = !this.createTime
      this.pageNum = 1
      this.handleOcrList()
    },
    handleOperation() {
      this.sortType = "handleTime"
      this.handleTime = !this.handleTime
      this.pageNum = 1
      this.handleOcrList()
    },
    /** 搜索 */
    async handleSearch() {
      this.pageNum = 1
      this.handleOcrList()
    },
    /** 上传 */
    handleUp() {
      this.clientVisible = true
      // window.open(process.env.VUE_APP_DOWNLOAD)
    },
    /** 选中的图书 */
    handleCheckedBook(id, checked) {
      if (checked) {
        this.checkedList.push(id)
      } else {
        const index = this.checkedList.findIndex(item => item === id)
        this.checkedList.splice(index, 1)
      }
    },
    /** 列表模式 */
    handleListType() {
      this.showType = 'list'
    },
    /** 橱窗模式 */
    handleShopwindowType() {
      this.showType = 'shopwindow'
    },
    /** 新增图书 */
    handleAdd() {
      this.visible = true
      this.type = 'add'
    },
    /** 编辑图书 */
    handleEdit(id) {
      this.id = id
      this.visible = true
      this.type = 'edit'
    },
    /** 关闭弹窗 */
    handleClose() {
      this.visible = false
    },
    /** 每页显示条数改变 */
    onShowSizeChange(pageNum, pageSize) {
      this.pageSize = pageSize;
      this.handleOcrList()
    },
    /** 翻页 */
    handleChange()  {
      this.handleOcrList()
    },
    /** 获取ocr图书列表 */
    async handleOcrList() {
      this.checkedList = []
      const r = await ocrBookList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        bookName: this.inputValue,
        order: this.sortType,
        sort: this[this.sortType] ? 'asc' : 'desc'
      })
      this.bookList = r.rows || []
      this.total = r.total
    },
    // 新增成功加入列表
    async addOcrList(data) {
      this.bookList.unshift(data)
    },
    /** 下载 */
    handleDown() {
      if (this.checkedList.length) {
        let str = ''
        this.bookList.map(item => {
          this.checkedList.map(itm => {
            if (itm == item.id) {
              str = str + '《'  + item.bookName + "》"
            }
          })
        })
       
        this.bookNameList = str
        this.downVisible = true
      } else {
        this.$message.warning('请先选择要下载的图书');
      }
    },
    /** 下载成功回调 */
    async handleDownCallBack(value) {
      downloadByUrl({
        requestUrl: `ocr/befDownload/${this.checkedList.join(',')}/${value}`,
        url: `ocr/download/${this.checkedList.join(',')}/${value}`,
        target: '_self',
      })
      this.downVisible = false
      this.checkedList = []
    },
    /** 关闭下载弹窗 */
    handleDownClose() {
      this.downVisible = false
    },
    /** 删除回调 */
    async handleDeleteCallBack (value) {
      try {
        const res = await deleteBook({ids: this.checkedList})
        this.$message.success(res.data);
        this.handleOcrList();
        this.checkedList = [];
        this.deleteVisible = false
      } catch (error) {
        this.handleOcrList();
        this.checkedList = [];
        this.deleteVisible = false
        console.log('error', error.msg)
      }
    },
    /** 删除 */
    async handleDelete() {
      if (!this.checkedList.length) {
        this.$message.warning('请先选择要删除的图书');
      } else {
        this.picNum = 0
        this.textNum = 0
        this.bookList.map(item => {
          this.checkedList.map(itm => {
            if (item.id == itm) {
              this.textNum += item.textCount 
              this.picNum += item.countPage
            }
          })
        })
        this.deleteVisible = true
      }
    },
    /** 关闭删除弹窗 */
    handleDeleteClose() {
      this.deleteVisible = false
    },
    handleClientClose() {
      this.clientVisible = false
    }
  },
};
</script>

<style lang="less">
.content-ocr {
  width: 100%;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .pagination {
    margin-right: 16px;
  }

  .content {
    width: auto;
    padding: 0px 51px;

    .input {
      margin-right: 22px;
    }

    .list {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 0 16px 24px;
      box-sizing: border-box;
  
      .top {
        width: calc(100% - 24px);
        height: 59px;
        border-bottom: 1px dashed #c6c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .addBook {
          width: 113px;
          height: 34px;
          font-size: 14px;
          font-weight: 600;
          color: #FFFFFF;
          background: url(../../assets/addBook.png) no-repeat;
          background-size: cover;
          padding-left: 40px;
          line-height: 34px;
          cursor: pointer;
          margin-right: 40px;
        }

        .left {
          // width: 335px;
          height: 20px;
          display: flex;
        }
        .right {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .showType {
            width: 140px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4A4A4A;
            }

            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
              &:nth-of-type(2) {
                width: 20px;
                height: 20px;
              }
            }
          }        
        }
      }

      .bottom {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        flex-wrap: wrap;

        &-content {
          width: 100%;
          display: flex;
          // justify-content: space-evenly;
          flex-wrap: wrap;
        }

        .add-wrap {
          width: calc(20% - 24px);
          height: 366px;
          background: #EEF3FF;
          position: relative;
          margin-right: 24px;

          img {
            width: 100%;
            height: 100%;
          }

          .add {
            width: 80px;
            height: 80px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-40px, -40px);
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .book-list {
          width: 100%;
          min-height: 300px;
          margin-right: 24px;
        }
      }
    }
  }
}

</style>
