<template>
<div class="book-wrap">
  <div class="bookImg">
    <Checkbox
      class="check-box"
      @change.stop="handleChange($event, bookItem.id)"
      :checked="checkedList.indexOf(bookItem.id) == -1 ? false : true"
      />
    <div>
      <img :src="
        bookItem.coverImage ?
        `${imgPath}${bookItem.coverImage}`
        : require('@/assets/image-default.png')"
      />
      <span
        class="icon-upload"
        v-if="bookItem.bookProgressOcr != 2 && !bookItem.coverImage"
      >
        <input
          type="file"
          multiple="multiple"
          id="files"
          accept="image/png,image/jpeg,image/tiff,image/tif,application/pdf,.djvu"
          @change="handleImgChange(bookItem.id, $event)"
        />
        上传文件
      </span>
      <span class="icon-upload" v-if="bookItem.coverImage && bookItem.bookType == 0" @click="goBookDetail(bookItem.id, bookItem.bookName,  bookItem.bookProgressOcr)">查看图像</span>
    </div>
  </div>
  <div class="bookInfo">
    <div class="bookName">
      <div />
      <p :title="bookItem.bookName"> 《{{bookItem.bookName}}》 </p>
    </div>
    <div class="bookIntroduce">
      <span v-if="bookItem.endHandleTime" style="color: #666;">保存期限：{{ bookItem.endHandleTime }}</span>
      <span>页数： {{bookItem.countPage}}页   识别页数：{{bookItem.countOcrPage}}页   字数：{{bookItem.countWord}}字</span>
      <span>修改字数：{{bookItem.countEdit}}字</span>
    </div>
  </div>
  <div class="bookSchedule">
    <div>
      <span>进度</span>
      <img
        v-for="item in scheduleArr" 
        :key="item.title" 
        :src="
          bookItem[item.title] === 1 ? item.checked
          : bookItem[item.title] === 0 ? item.unChecked
          : bookItem[item.title] === 2 ? item.error : ''" 
        
      >
    </div>
    <img src="../assets/zhixiang.png" v-if="bookItem.bookType == 0" @click="goBookDetail(bookItem.id, bookItem.bookName,  bookItem.bookProgressOcr)" class="icon-enter">
  </div>
  <img src="../assets/biaoqian.png"  class="img" @click="handleEdit(bookItem.id)">

  <!-- 提示 -->
  <TipsModal
    v-if="imgTipsVisible"
    :visible="imgTipsVisible"
    tipsType="tipsFour"
    buttonText="我知道了"
    :handleClose="tipsImageCallBack"
    :limitImageObj="limitImageObj"
  />

  <!-- 上传失败弹窗提示 -->
  <UploadModal
    v-if="upFailVisible"
    :visible='upFailVisible'
    :handleClose='closeUploadModal'
    :exceedFiveList='exceedFiveList'
    :jamList='jamList'
    :type='upFailCondition'
    :fileFailCount="fileFailCount"
  />

  <!-- 上传中提示 -->
  <HintOrDownModal
    v-if="tipsLoadingVisible"
    title="提示"
    tipsType="tipsTen"
    :visible="tipsLoadingVisible"
    :handleClose="handleLoadingClose"
    :handleCallback="handleLoadingClose"
  />
</div>
</template>
<script>
import { Checkbox  } from 'ant-design-vue';
import unCheckedPic from '../assets/picture.png';
import unCheckedOcr from '../assets/OCR-g.png';
import unCheckedText from '../assets/wenben.png';
import unCheckedA from '../assets/daxieA.png';
import unCheckedTransform from '../assets/fanjian.png'

import checkedPic from '../assets/checkedPic.png';
import checkedOcr from '../assets/checkedOcr.png';
import checkedText from '../assets/checkedText.png';
import checkedA from '../assets/checkedA.png';
import checkedTransform from '../assets/checkedFanjian.png'

import errorPic from '@/assets/picture-red.png';
import errorOcr from '@/assets/ocr-red.png';
import errorText from '@/assets/text-red.png';
import errorA from '@/assets/a-red.png';
import errorTransform from '@/assets/fanjian-red.png'
import HintOrDownModal from '@/components/HintOrDownModal.vue';
import { getImglist, getLimitImageBook } from '@/api/ocr';
import axios from 'axios'
import { getToken } from '@/utils/auth'
import TipsModal from '@/components/TipsModal.vue'
import UploadModal from '@/components/UploadModal.vue';

export default {
  components: { Checkbox,  HintOrDownModal, TipsModal, UploadModal},
  props: ['handleEdit', 'bookItem', 'handleCheckedBook', 'checkedList', 'userInfo'],
  data() {
    return {
      scheduleArr: [
        {
          title: 'bookProgressImg',
          type: '',
          checked: checkedPic,
          unChecked: unCheckedPic,
          error: errorPic,
          text: '图文校对'
        },
        {
          title: 'bookProgressOcr',
          checked: checkedOcr,
          unChecked: unCheckedOcr,
          error: errorOcr,
          text: 'ocr识别',
          type: 'ocrFlag',
        },
        {
          title: 'bookProgressBd',
          checked: checkedText,
          unChecked: unCheckedText,
          error: errorText,
          text: '标点',
          type: 'bdFlag',
        },
        {
          title: 'bookProgressBx',
          checked: checkedA,
          unChecked: unCheckedA,
          error: errorA,
          text: '标点标线',
          type: 'bxFlag',
        },
        {
          title: 'bookProgressSimple',
          checked: checkedTransform,
          unChecked: unCheckedTransform,
          error: errorTransform,
          text: '繁转简',
          type: 'transFlag',
        },
      ],
      imgPath: process.env.VUE_APP_IMG_PATH,
      id: '', 
      bookName: '',
      tipsVisible: false,
      imgTipsVisible: false, // 图片超出提示
      upFailVisible: false,
      upFailCondition: 'all', // all 全部 a 情况a b 情况b
      // 超过5兆照片集合
      exceedFiveList: [],
      //拥堵集合
      jamList: [],
      limitImageObj: {},
      tipsLoadingVisible: false
    }
  },
  methods: {
    async goBookDetail(id, bookName, ocrStatus) {
      try {
        await getImglist({
          sysBookId: id,
        })
        this.$router.push(`/ocrImg?id=${id}&bookName=${bookName}&ocrStatus=${ocrStatus}`)
      } catch (error) {
        this.$emit('refresh')
      }
    },
    handleOneChange() {
      this.tipsVisible = true;
    },
    /** 复选框 */
    handleChange(e, id) {
      const { checked }  = e.target
      this.handleCheckedBook(id, checked)
    },
    // 上传图片 
    async handleImgChange(bookId, info) {
      this.loading = true;
      let formData = new FormData()
      let filesList = Object.values(info.target.files)

      const res = await getLimitImageBook({
        bookId: bookId,
      })

      if (res.data.isBookLimit) {
        let num = 0
        filesList.map(item => {
          num = num + item.size
        })
        if (num > res.data.thisBookMaxLimitToB) {
          this.limitImageObj = res.data
          this.imgTipsVisible = true
          this.loading = false;
          document.querySelector('#files').value = null
          return
        }
      }
  
      let fileFlag = false
      filesList.map(item => {
        if ('application/pdf.djvu'.indexOf(item.type) != -1 && filesList.length > 1) {
          fileFlag = true
        }
        formData.append('files', item)
      })

      // 单个Pdf或Djvu
      if (fileFlag) {
        this.$message.error('一本书仅支持上传单个Pdf或Djvu，请您分多本书上传。');
        this.loading = false;
        document.querySelector('#files').value = null
        return
      }

      this.uploadURL = process.env.VUE_APP_BASE_API + `ocr/uploadImgs/${bookId}`

      this.tipsStep = 'loading'
      this.tipsLoadingVisible = true
      axios({
        url: this.uploadURL,
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
        data: formData
      })
      .then((response) =>{
        this.tipsLoadingVisible = false
        if (response.data.code == 200) {
          if (response?.data?.data) {
            this.exceedFiveList = response.data.data.imgNameFailSizeList
            this.jamList = response.data.data.imgNameFailErrorList
            this.upFailVisible = true
            this.fileFailCount = response.data.data.fileFailCount
          } else {
            this.$message.success('上传成功');
          }
        } else {
          this.$message.error(response.data.msg || '上传有误!');
        }
        this.loading = false;
        document.querySelector('#files').value = null
        this.$emit('refresh')
      })
      .catch((error) => {
        document.querySelector('#files').value = null
        this.loading = false;
        this.$message.error(error.data.msg || '上传有误!');
      })
    },
    // 图片超限提示
    tipsImageCallBack() {
      this.imgTipsVisible = false
    },
    closeUploadModal() {
      this.upFailVisible = false
    },
    handleLoadingClose() {
      this.tipsLoadingVisible = false
    }
  }
}

</script>
<style lang="less">
.book-wrap {
  width: calc(20% - 24px);
  height: 366px;
  background: #FFFFFF;
  border: 1px solid #E9DDD2;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  margin-right: 24px;

  .bookImg {
    width: 100%;
    height: 197px;
    border-bottom: 1px dashed #C6C4C4 ;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 20px;
    img {
      max-width: 100%;
      max-height: 189px;
    }
    div {
      position: relative;
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -56px;
        margin-top: -17px;
        display: none;
      }
    }
  }

  .bookImg:hover {
    .icon-upload {
      display: flex;
      align-items: center;
      width: 113px;
      height: 34px;
      background: url(../assets/upbeijing.png) no-repeat;
      background-size: cover;
      padding-left: 40px;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      opacity: 0.67;
      cursor: pointer;
      input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }
    }
  }

  .bookInfo {
    width: 100%;
    height: 134px;
    border-bottom: 1px dashed #C6C4C4;
    padding: 4px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-start;

    .bookName {
      width: 100%;
      display: flex;
      height: 22px;
      margin-left: -7px;
      align-items: center;

      div {
        flex: 8px 0 0;
        width: 8px;
        height: 8px;
        background: #FFCD04;
        border-radius: 50%;
        margin-left: -8px;
      }
     
      p {
        width: calc(100% - 10px);
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    .bookIntroduce {
      width: auto;
      height: 62px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 10px;

      span {
        display: inline-block;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        text-align: left;
      }
    }
  }

  .bookSchedule {
    flex: 1;
    padding: 0 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
      width: 24px;
      height: 21px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }

    div {
      height: 18px;
      display: flex;
      align-items: center;

      img {
        width: 19px;
        height: 19px;
        margin-right: 6px;
        &:nth-of-type(1) {
          margin-left: 10px;
        }
      }
    }

    .icon-enter {
      width: 32px;
      height: 16px;
      cursor: pointer;
    }
  }

  .img {
    width: 29px;
    height: 29px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  .check-box {
    position: absolute;
    left: 0;
    top: -1px;
    z-index: 1;
  }
}
</style>